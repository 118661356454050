<template>
  <div class="page customers" v-if="!forbid && user">
    <div class="roll_back_line flex justify-between">
      <div class="inline-block flex w-1/3 justify-start items-center">
        <router-link to="/vehicles" class="flex items-center">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 18L8 12L14 6" stroke="#6c757d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

          <div class="h-full label_roll_back">
            Vehicles
          </div>
        </router-link>
      </div>
    </div>

    <div class="title_line flex justify-between">
      <div class="inline-block flex w-1/3 justify-start items-center">
        <h1 class="text-left" v-if="add_new">Add new vehicle</h1>
        <h1 class="text-left" v-else>Edit vehicle</h1>
      </div>
    </div>

    <div class="form_body flex justify-between">
      <div class="w-1/5">
        <div class="form_label text-left">
          Vehicle Details
        </div>
      </div>

      <div class="w-4/5 block">
        <div class="w-full block md:flex">
          <div class="form_row w-full md:w-1/2 p-4">
            <label for="name" class="block text-gray-700 mb-2 text-left input_label">
              Name <span>*</span>
            </label>
            <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                @input="toggleSaveButton('name')"
                @click="skipErrors('name')"
                v-model="form.name"
                type="text"
                placeholder="i.e 'MY lorry'">
            <p v-if="errors.name" class="text-red-500 text-xs italic text-left py-2">
              Vehicle make must be at least 3 characters long
            </p>
          </div>

          <div class="form_row w-full md:w-1/2 p-4">
            <label for="reg_number" class="block text-gray-700 mb-2 text-left input_label">
              Vehicle registration number <span>*</span>
            </label>
            <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="reg_number"
                @input="toggleSaveButton"
                @click="skipErrors('registration_number')"
                v-model="form.registration_number"
                type="text"
                placeholder="i.e '23232'">
            <p v-if="errors.registration_number" class="text-red-500 text-xs italic text-left py-2">
              Registration number must be at least 5 characters long
            </p>
          </div>
        </div>

        <div class="w-full block md:flex">
          <div class="form_row w-full md:w-1/2 p-4">
            <label for="vehicle_make" class="block text-gray-700 mb-2 text-left input_label">
              Vehicle make <span>*</span>
            </label>
            <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="vehicle_make"
                @input="toggleSaveButton"
                @click="skipErrors('vehicle_make')"
                v-model="form.vehicle_make"
                type="text"
                placeholder="i.e 'Mercedes-Benz'">
            <p v-if="errors.vehicle_make" class="text-red-500 text-xs italic text-left py-2">
              Vehicle make must be at least 3 characters long
            </p>
          </div>

          <div class="form_row w-full md:w-1/2 p-4">
            <label for="grid-state" class="block text-gray-700 mb-2 text-left input_label">
              Vehicle group
            </label>
            <div class="relative">
              <select
                  @select="toggleSaveButton"
                  v-model="form.vehicle_group"
                  class="block shadow appearance-none border rounded w-full bg-white border-gray-200 text-gray-400 py-2 px-3 pr-8 leading-tight focus:text-gray-700 focus:outline-none focus:shadow-outline"
                  id="grid-state">
                <option v-if="add_new" disabled value=""><span class="span_in_select">{{ form.vehicle_group ? form.vehicle_group : 'Select...' }}</span></option>
                <option v-else disabled><span class="span_in_select">{{ form.vehicle_group ? form.vehicle_group : 'Select...' }}</span></option>
                <option
                    v-for="(group_vehicles, index) in vehicle_groups"
                    :key="'vehicle-group-' + index"
                    :value="group_vehicles.id">
                  {{group_vehicles.name}}
                </option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full block md:flex">
          <div class="form_row w-full md:w-1/3 p-4">
            <label for="height" class="block text-gray-700 mb-2 text-left input_label">
              Height <span>*</span>
            </label>
            <span class="input_span">
              <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="height"
                  @input="toggleSaveButton"
                  @click="skipErrors('height')"
                  v-model="form.height"
                  type="text"
                  placeholder="1.5">
              <span class="units">
                m
              </span>
            </span>
            <p v-if="errors.height" class="text-red-500 text-xs italic text-left py-2">
              This field is required
            </p>
          </div>

          <div class="form_row w-full md:w-1/3 p-4">
            <label for="width" class="block text-gray-700 mb-2 text-left input_label">
              Width <span>*</span>
            </label>
            <span class="input_span">
              <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="width"
                  @input="toggleSaveButton"
                  @click="skipErrors('width')"
                  v-model="form.width"
                  type="text"
                  placeholder="1.5">
              <span class="units">
                m
              </span>
            </span>
            <p v-if="errors.width" class="text-red-500 text-xs italic text-left py-2">
              This field is required
            </p>
          </div>

          <div class="form_row w-full md:w-1/3 p-4">
            <label for="length" class="block text-gray-700 mb-2 text-left input_label">
              Length <span>*</span>
            </label>
            <span class="input_span">
              <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="length"
                  @input="toggleSaveButton"
                  @click="skipErrors('length')"
                  v-model="form.length"
                  type="text"
                  placeholder="1.5">
              <span class="units">
                m
              </span>
            </span>
            <p v-if="errors.length" class="text-red-500 text-xs italic text-left py-2">
              This field is required
            </p>
          </div>
        </div>

        <div class="w-full block md:flex">
          <div class="form_row w-full md:w-1/3 p-4">
            <label for="max_gross" class="block text-gray-700 mb-2 text-left input_label">
              Maximum Gross Weight <span>*</span>
            </label>
            <span class="input_span">
              <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="max_gross"
                  @input="toggleSaveButton"
                  @click="skipErrors('weight')"
                  v-model="form.weight"
                  type="text"
                  placeholder="1.5">
              <span class="units">
                t
              </span>
            </span>
            <p v-if="errors.weight" class="text-red-500 text-xs italic text-left py-2">
              This field is required
            </p>
            <p :class="errors.weight ? 'mt-2' : 'mt-4'" class="text-justify">
              As indicated on the vehicle's Plating Certificate if the vehicle is articulated or is equipped with a drawbar, enter the gross train weight of the vehicle and trailer.
            </p>
          </div>

          <div class="form_row w-full md:w-1/2 p-4">
            <label for="grid-state-type" class="block text-gray-700 mb-2 text-left input_label">
              Vehicle type
            </label>
            <div class="relative" v-if="user.api_version === 'v2'">
              <select
                  @select="toggleSaveButton"
                  v-model="form.vehicle_type"
                  class="block shadow appearance-none border rounded w-full bg-white border-gray-200 text-gray-400 py-2 px-3 pr-8 leading-tight focus:text-gray-700 focus:outline-none focus:shadow-outline"
                  id="grid-state-type">
                <option disabled value=""><span class="span_in_select">Select...</span></option>
                <option
                    v-for="(vehicle_type) in vehicle_types_v2"
                    :key="'vehicle-type-' + vehicle_type.id"
                    :value="vehicle_type.id">{{vehicle_type.name}}</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
            </div>

            <div class="relative" v-else>
              <select
                  @select="toggleSaveButton"
                  v-model="form.vehicle_type"
                  class="block shadow appearance-none border rounded w-full bg-white border-gray-200 text-gray-400 py-2 px-3 pr-8 leading-tight focus:text-gray-700 focus:outline-none focus:shadow-outline"
                  id="grid-state-type">
                <option disabled value=""><span class="span_in_select">Select...</span></option>
                <option
                    v-for="(types_vehicle, index) in vehicle_types"
                    :key="'vehicle-type-' + index"
                    :value="types_vehicle">{{types_vehicle}}</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full flex justify-end items-center py-6 px-2">
          <button
              @click="cancelForm"
              class="this_button mr-4 py-3 px-1 bg-gray-200 border border-gray-300">
            Cancel
          </button>

          <button
              @click="saveForm"
              :disabled="update_disable && add_new"
              :class="update_disable && add_new? 'text-white bg-gray-400 border-gray-500' : 'text_chameleon bg-green-custom hover:bg-gray-200 border-green-custom'"
              class="this_button py-3 px-1 border">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="component page" v-else>
    <OutOfLimit
        :items="'vehicles'"
        :itemsProps="'vehicles limit'"
        :count="allowedCount"
    />
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {infoMessage} from "../../services/messages";
import OutOfLimit from "@/components/OutOfLimit";

export default {
  name: "AddVehicles",
  data() {
    return {
      user: null,
      forbid: false,
      allowedCount: 0,
      update_disable: true,
      vehicle_groups: [],
      vehicle_types_v2: [
        {id: 'straightTruck', name: 'Straight Truck'},
        {id: 'tractor', name: 'Semi Truck'},
        {id: 'bus', name: 'Bus'},
        {id: 'privateBus', name: 'Private Bus'},
        {id: 'taxi', name: 'Taxi'},
      ],

      vehicle_types: [
        'Good vehicles (more than 7.5 tonnes maximum laden weight)',
        'Good vehicles (more than 7.5 tonnes maximum laden weight) in England and Wales',
        'Good vehicles (more than 7.5 tonnes maximum laden weight) in Scotland',
        'Vans weighing up to 3.5 tonnes',
        'Vans with trailers',
        'Car-derived vans (weighing up to 2 tonnes)',
        'Dual purpose vehicle (pick up trucks)',
        'Buses, coaches and minibuses (not more than 12 meters overall length)',
        'Buses, coaches and minibuses (more than 12 meters overall length)',
        'Cars',
        'Cars when towing caravans or trailers',
      ],
      form : {
        name: null,
        registration_number: null,
        vehicle_make: null,
        height: null,
        width: null,
        length: null,
        weight: null,
        user_id: null,
        vehicle_type: '',
        vehicle_group: '',
        vehicle_group_name: '',
      },
      errors: {
        name: false,
        registration_number: false,
        vehicle_make: false,
        height: false,
        weight: false,
        length: false,
        width: false,
        user_id: false,
        vehicle_type: false,
      },
      add_new: true,
      vehicle_id: null
    }
  },
  methods: {
    ...mapMutations([]),
    continueCreate() {
      this.skipForm();
    },
    cancelForm() {
      this.$router.push('/vehicles');
    },
    saveForm() {
      if (this.validationForm()) {
        this.submitThisForm();
      }
    },
    async getUser() {
      this.user = JSON.parse(localStorage.getItem('user')) || null;
    },
    async checkAddOrEdit() {
      if (this.$route.params.id && this.$route.params.id != 0) {
        this.add_new = false;
        await this.fetchVehicle();
      } else {
        const isForbid = await await this.$http.getAuth(`${this.$http.apiUrl()}/vehicles/forbid`);
        if (isForbid.data.forbid.forbid) {
          this.forbid = isForbid.data.forbid.forbid;
          this.allowedCount = isForbid.data.forbid.count
        }
        this.add_new = true;
        await this.getUser();
        this.form.user_id = this.user.id;
      }
    },
    async fetchVehicle(){
      const response = await this.$http.getAuth(`${this.$http.apiUrl()}/vehicles/${this.$route.params.id}`);

      this.form = response?.data?.vehicle || null;
      this.vehicle_id = response?.data?.vehicle.id || null;
      this.form.vehicle_group = response?.data?.vehicle?.vehicle_groups[0]?.id || null;
      this.form.vehicle_group_name = response?.data?.vehicle?.vehicle_groups[0]?.name || null;

      if (!this.form) {
        return infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }
    },
    async getVehicleGroup() {
      const vehicle_groups_result = await this.$http.getAuth(`${this.$http.apiUrl()}/vehicle-groups/${this.user.id}/get-by-id`) || [];

      this.vehicle_groups = vehicle_groups_result?.data?.vehicleGroups?.data || [];
    },
    async submitThisForm() {
      try {
        if (this.add_new) {
          await this.$http.postAuth(`${this.$http.apiUrl()}/vehicles/create`, {body: this.form});
        } else {
          await this.$http.postAuth(`${this.$http.apiUrl()}/vehicles/${this.vehicle_id}`, {body: this.form});
        }
      } catch (e) {
        console.error(e);
        return infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }
      infoMessage(
          `Vehicles added!`,
          this.getTopBanner ? 'success open_top_banner' : 'success'
      );
      this.cancelForm();
    },
    ucFirst(str) {
      return str[0].toUpperCase() + str.substring(1);
    },
    validationForm() {
      this.skipErrors();

      let empty_errors = true;

      if (!this.form.name || this.form.name.length < 3) {
        this.errors.name = true;
        empty_errors = false;
      } else {
        this.form.name = this.ucFirst(this.form.name);
      }
      if (!this.form.registration_number || this.form.registration_number.length < 5) {
        this.errors.registration_number = true;
        empty_errors = false;
      }
      if (!this.form.vehicle_make || this.form.vehicle_make.length < 3) {
        this.errors.vehicle_make = true;
        empty_errors = false;
      }
      if (!this.form.height) {
        this.errors.height = true;
        empty_errors = false;
      }
      if (!this.form.width) {
        this.errors.width = true;
        empty_errors = false;
      }
      if (!this.form.length) {
        this.errors.length = true;
        empty_errors = false;
      }
      if (!this.form.weight) {
        this.errors.weight = true;
        empty_errors = false;
      }

      this.update_disable = !empty_errors;

      return empty_errors;
    },
    skipErrors(val = null) {
      if (val) {
        this.errors[val] = false;
      } else {
        for (let key in this.errors) {
          this.errors[key] = false;
        }
      }
    },
    skipForm() {
      this.form = {
        name: null,
        registration_number: null,
        vehicle_make: null,
        height: null,
        weight: null,
        length: null,
        width: null,
        user_id: null,
        vehicle_type: '',
        vehicle_group: '',
      };
    },
    toggleSaveButton(val = null) {
      if (val && val == 'name' && this.form.name.length > 1) {
        this.form.name = this.ucFirst(this.form.name);
      }
      for (let key in this.form) {
        if (key != 'vehicle_type' && key != 'vehicle_group' && key != 'vehicle_group_name' && !this.form[key]) {
          this.update_disable = true;
          return;
        }
      }
      this.update_disable = false;
    },
  },
  async mounted() {
    await this.getUser();
    await this.getVehicleGroup();
    await this.checkAddOrEdit();
    if (this.user) {
      this.form.user_id = this.user.id;
    }
  },
  computed: {
    ...mapGetters([
      'getTopBanner'
    ]),
  },
  components: {
    OutOfLimit
  }
}
</script>

<style lang="scss" scoped>
.item {
  padding-right: 25px;
}
.actions {
  &:hover, &:active, &:focus{
    .hidden {
      display: block;
    }
    span {
      &>* {
        cursor: pointer;
      }
      &:first-child {
        &>*{
          margin-left: auto;
        }
      }
    }
  }
}
.title_line {
  padding: 10px 15px 22px;
}

.form_body {
  width: 95%;
  padding: 30px 15px;
  margin: auto;
}
.form_label {
  color: #22292f;
  font-size: 1.17em;
  font-weight: 600;
}
.input_label {
  color: #22292f;
  font-size: 1em;
  font-weight: 600;
  span {
    color: #28a745;
  }
}
.span_in_select {
  color: #6c757d !important;
}
.input_span {
  position: relative;
}
.units {
  position: absolute;
  top: 3px;
  right: 15px;
}
.this_button {
  font-size: 1.17em;
  font-weight: 700;
  border-radius: 5px;
  width: 64px;
}
</style>
